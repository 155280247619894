<template>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-popup title="Nouvelle rubrique" :active.sync="popupNouvelleRubrique">
          <!-- Content Row -->
          <div class="vx-row">
            <div class="vx-col w-full">
                <vs-input class="w-full mt-4" label="Libelle*" v-model="Libelle" name="Fournisseur" />
                <vs-textarea class="w-full mt-4" counter="250" label="Description" :counter-danger.sync="counterDanger" v-model="Description" />
            </div>
          </div>
          <!-- Save & Reset Button -->
          <div class="vx-row">
            <div class="vx-col w-full">
              <div class="mt-8 flex flex-wrap items-center justify-end">
                <vs-button :disabled="!validateForm" class="ml-auto mt-2" @click="createRubrique()">ENREGISTRER</vs-button>
                <vs-button class="ml-4 mt-2" type="border" color="warning" @click="reset_data()">ANNULER</vs-button>
              </div>
            </div>
          </div>
        </vs-popup>
  
        <vs-popup title="Modifier une rubrique"  :active.sync="popupUpdateRubrique">
          <!-- Content Row -->
          <div class="vx-row">
            <div class="vx-col w-full">
                <vs-input class="w-full mt-4" label="Libelle*" v-model="Libelle" name="Fournisseur" />
                <vs-textarea class="w-full mt-4" counter="250" label="Description" :counter-danger.sync="counterDanger" v-model="Description" />
            </div>
          </div>
          <!-- Save & Reset Button -->
          <div class="vx-row">
            <div class="vx-col w-full">
              <div class="mt-8 flex flex-wrap items-center justify-end">
                <vs-button :disabled="!validateForm" class="ml-auto mt-2" @click="updateRubrique()">MODIFIER</vs-button>
                <vs-button class="ml-4 mt-2" type="border" color="warning" @click="reset_data()">ANNULER</vs-button>
              </div>
            </div>
          </div>
        </vs-popup>
        <vx-card >
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <vs-table stripe noDataText="Aucune donnée" pagination max-items="50" search :data="rubrique_depense_data">
                <template slot="header">
                  <vs-button class="mb-4 mr-5 md:mb-0" @click.stop="popupNouvelleRubrique=true">Nouvelle rubrique</vs-button>
                </template>
                <template slot="thead">
                  <vs-th>
                    N°
                  </vs-th>
                  <vs-th sort-key="Libelle">
                    Libelle
                  </vs-th>
                  <vs-th sort-key="Description">
                    Description
                  </vs-th>
                  <vs-th>
                    Actions
                  </vs-th>
                </template>
  
                <template slot-scope="{data}">
                  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                    <vs-td>
                      {{indextr + 1}}
                    </vs-td>
  
                    <vs-td :data="tr.Libelle">
                      {{tr.Libelle}}
                    </vs-td>
  
                    <vs-td :data="tr.Description">
                      {{tr.Description}}
                    </vs-td>
                    <vs-td>
                      <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
                        <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="updateRubriqueDepenseShowForm(tr)" />
                        <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord(tr)" />
                      </div>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </template>
<script>
export default {
  data () {
    return {
      popupNouvelleRubrique: false,
      popupUpdateRubrique: false,
      IdUser: null,
      IdRubrique: null,
      activeUserInfos: null,
      Libelle: null,
      Description: null,

      counterDanger: false
    }
  },
  computed: {
    rubrique_depense_data () {
      return this.$store.state.rubrique_depense.rubriques
    },
    validateForm () {
      return !this.errors.any() && this.Libelle !== '' && this.Libelle !== null
    }
  },
  
  methods: {
    updateRubriqueDepenseShowForm (data) {  
      this.IdRubrique = data._id
      this.Libelle = data.Libelle
      this.Description = data.Description
      this.popupUpdateRubrique = true
    },
    updateRubrique () {
      const payload = {
        IdUser: this.IdUser,
        Libelle: this.Libelle.trim(),
        Description: this.Description,
        id: this.IdRubrique
      }
  
      this.$vs.loading({
        type:'sound'
      })
  
      this.$store.dispatch('rubrique_depense/updateRubrique', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
  
          this.$vs.loading.close()
          this.popupUpdateRubrique = false
          this.IdRubrique = null
          this.reset_data()
        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
        })
    },
    confirmDeleteRecord (data) {
      this.IdRubrique = data._id
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmé la Suppression',
        text: `Vous allez supprimer la rubrique  "${data.Libelle}`,
        accept: this.deleteRecord,
        acceptText: 'Supprimer'
      })
    },
    deleteRecord () {
      const payload = {
        id: this.IdRubrique
      }
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('rubrique_depense/softDeleteRubrique', payload)
        .then(() => {
          this.$vs.loading.close()
          this.showDeleteSuccess()
          this.IdRubrique = null
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'danger',
        title: 'Supprimer',
        text: 'La rubrique sélectionnée a bien été supprimée'
      })
    },
    createRubrique () {
      const payload = {
        IdUser: this.IdUser,
        Libelle: this.Libelle.trim(),
        Description: this.Description
      }
  
      this.$vs.loading({
        type:'sound'
      })
  
      this.$store.dispatch('rubrique_depense/createRubrique', payload)
        .then(() => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
  
          this.popupNouvelleRubrique = false
          this.reset_data()
        })
        .catch((error) => {
          this.$vs.loading.close()
          console.log(error)
        })
    },
  
    reset_data () {
      this.Libelle = null
      this.Description = null
    },
  
    getRubriques () {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('rubrique_depense/getRubriques')
        .then(() => {
          this.$vs.loading.close()
        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
        })
    },
  
    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
      this.IdUser = this.activeUserInfos._id
    }
  },
  created () {
    this.getRubriques()
    this.activeUserInfo()
  }
}
</script>
  